import { useRouter } from 'next/router';
import React, { useRef } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Badge } from '@sravni/react-design-system/lib/Badge';
import { Button } from '@sravni/react-design-system/lib/Button';
import { Icon } from '@sravni/react-design-system/lib/Icon';
import { Space } from '@sravni/react-design-system/lib/Space';
import { Plus } from '@sravni/react-icons';

import { SessionStorageKeys } from '@src/@types/sessionStorage';
import { FullDeal } from '@src/assets';
import { OptimizedPicture } from '@src/components/ui/OptimizedPicture';
import { StickyBlock } from '@src/containers/list/ProductList/FullDealEntryPoint/components/StickyBlock';
import { sendOpenAppFullDealEvent, sendOpenWebFullDealEvent } from '@src/helpers/analyticsEvents';
import { useVisibility } from '@src/hooks/useVisibility';
import { useMicrocreditUserDealsState } from '@src/reducers/microcreditUserDeals';

import styles from './styles.module.scss';
import { FULL_DEAL_ENTRY_POINT_TEXTS, ONE_LINK, WEB_FULL_DEAL_LINK } from './сonstants';

const { Heading, Text } = Typography;

export const FullDealEntryPoint = () => {
    const { asPath } = useRouter();
    const microcreditDeals = useMicrocreditUserDealsState();

    const hasIssuedMicrocreditDeals = microcreditDeals.some((deal) => deal.status === 'issued');

    const ref = useRef<HTMLDivElement>(null);
    const isFullDealEntryVisible = useVisibility(ref);

    const handleOpenWebFullDeal = (event: React.SyntheticEvent) => {
        event.stopPropagation();

        sessionStorage.setItem(SessionStorageKeys.referer, asPath);

        sendOpenWebFullDealEvent(
            hasIssuedMicrocreditDeals
                ? 'Новый займ (Не хотите искать займ?)'
                : FULL_DEAL_ENTRY_POINT_TEXTS.redirectButtonText,
        );

        window.location.href = WEB_FULL_DEAL_LINK;
    };

    const handleOpenMobileApp = (event: React.SyntheticEvent) => {
        event.stopPropagation();

        sendOpenAppFullDealEvent('Не хотите искать займ?');
        window.open(ONE_LINK, '_blank');
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <OptimizedPicture width={176} height={176} img={FullDeal} alt="fullDeal" />

                    <div className={styles.content}>
                        <Badge variant="primary" color="green" text="Первый займ под 0%" />
                        <Heading level={3}>{FULL_DEAL_ENTRY_POINT_TEXTS.heading}</Heading>

                        <Text size={14} className={styles.description}>
                            {FULL_DEAL_ENTRY_POINT_TEXTS.description}
                        </Text>
                    </div>

                    <Space ref={ref} size={8} direction="horizontal">
                        {hasIssuedMicrocreditDeals && (
                            <Button
                                className={styles.button}
                                size={60}
                                block
                                color="gray"
                                onClick={handleOpenMobileApp}
                            >
                                {FULL_DEAL_ENTRY_POINT_TEXTS.mobileAppButtonText}
                            </Button>
                        )}

                        <Button
                            className={styles.button}
                            size={60}
                            block
                            variant="primary"
                            color="blue"
                            onClick={handleOpenWebFullDeal}
                        >
                            {hasIssuedMicrocreditDeals ? (
                                <>
                                    <Icon size={20} icon={<Plus />} />
                                    {FULL_DEAL_ENTRY_POINT_TEXTS.newDealButtonText}
                                </>
                            ) : (
                                FULL_DEAL_ENTRY_POINT_TEXTS.redirectButtonText
                            )}
                        </Button>
                    </Space>
                </div>
            </div>

            {!isFullDealEntryVisible && <StickyBlock onButtonClick={handleOpenWebFullDeal} />}
        </>
    );
};
