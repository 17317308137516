import React, { useCallback } from 'react';

import { Button } from '@sravni/react-design-system';
import { useIsMobile } from '@sravni/react-utils';
import pluralize from '@sravni/utils/lib/pluralize';

import { HelperText } from '@src/components/details/components/PageHeaderFilters/components/HelperText';
import { MobilePageHeading } from '@src/components/details/components/PageHeaderFilters/components/MobilePageHeading';
import {
    AMOUNT_LABEL,
    AMOUNT_MAX,
    AMOUNT_MIN,
    AMOUNT_POSTFIX,
    AMOUNT_SLIDER_STEP,
    AMOUNT_TEXT,
    BUTTON_TEXT,
    TERM_LABEL,
    TERM_MAX,
    TERM_MIN,
    TERM_TEXT,
} from '@src/components/details/components/PageHeaderFilters/consts';
import { DESKTOP_BUTTON_SIZE, MOBILE_BUTTON_SIZE } from '@src/components/PageHeader/consts';
import { AmountControlV2 } from '@src/components/ui/AmountControlV2';
import { TermControl } from '@src/components/ui/TermControl';
import { PRODUCT_LIST_ANCHOR } from '@src/constants/common';
import { FILTERS_NAMES } from '@src/constants/filters';
import { scrollToElementById } from '@src/helpers/common';

import styles from './styles.module.scss';

type Props = {
    initialAmount: number;
    initialTerm: number;
    onChange: (value: any, name: string) => void;
};

export const PageHeaderFilters = ({ initialAmount, initialTerm, onChange }: Props) => {
    const isMobile = useIsMobile();

    const handleClickShowOffers = useCallback(() => {
        scrollToElementById(PRODUCT_LIST_ANCHOR);
    }, []);

    const buttonSize = isMobile ? MOBILE_BUTTON_SIZE : DESKTOP_BUTTON_SIZE;
    const termPostfix = pluralize(initialTerm, 'день', 'дня', 'дней') ?? 'день';

    if (isMobile) {
        return <MobilePageHeading initialAmount={initialAmount} onChange={onChange} initialTerm={initialTerm} />;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.filters}>
                <div className={styles.sumField}>
                    <AmountControlV2
                        name={FILTERS_NAMES.AMOUNT}
                        label={AMOUNT_LABEL}
                        value={initialAmount}
                        min={AMOUNT_MIN}
                        max={AMOUNT_MAX}
                        postfix={AMOUNT_POSTFIX}
                        onChange={onChange}
                        steps={[{ ceilThreshold: AMOUNT_MAX, step: AMOUNT_SLIDER_STEP }]}
                    />
                    <HelperText text={AMOUNT_TEXT} />
                </div>

                <div className={styles.sumField}>
                    <div>
                        <TermControl
                            name={FILTERS_NAMES.TERM}
                            label={TERM_LABEL}
                            value={initialTerm}
                            onChange={onChange}
                            postfix={termPostfix}
                            min={TERM_MIN}
                            max={TERM_MAX}
                        />
                        <HelperText text={TERM_TEXT} />
                    </div>
                </div>

                <Button variant="primary" size={buttonSize} onClick={handleClickShowOffers} className={styles.button}>
                    {BUTTON_TEXT}
                </Button>
            </div>
        </div>
    );
};
