import type { FC, SyntheticEvent } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Button } from '@sravni/react-design-system/lib/Button';

import { FULL_DEAL_ENTRY_POINT_TEXTS } from '@src/containers/list/ProductList/FullDealEntryPoint/сonstants';

import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
    onButtonClick: (event: SyntheticEvent) => void;
};

export const StickyBlock: FC<Props> = ({ onButtonClick }) => (
    <div className={styles.stickyWrapper}>
        <div className={styles.content}>
            <Text className="h-color-L100" size={14}>
                {FULL_DEAL_ENTRY_POINT_TEXTS.heading}
            </Text>
            <Text className="h-color-D20" size={12}>
                Заполните одну анкету
            </Text>
        </div>
        <Button className={styles.button} size={36} block variant="primary" color="blue" onClick={onButtonClick}>
            {FULL_DEAL_ENTRY_POINT_TEXTS.redirectButtonText}
        </Button>
    </div>
);
