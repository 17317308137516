import cn from 'classnames';
import { memo } from 'react';

import pluralize from '@sravni/utils/lib/pluralize';

import { HelperText } from '@src/components/details/components/PageHeaderFilters/components/HelperText';
import {
    AMOUNT_BADGE_LIST,
    TERM_BADGE_LIST,
} from '@src/components/details/components/PageHeaderFilters/components/MobilePageHeading/consts';
import styles from '@src/components/details/components/PageHeaderFilters/components/MobilePageHeading/styles.module.scss';
import {
    AMOUNT_LABEL,
    AMOUNT_POSTFIX,
    AMOUNT_TEXT,
    TERM_LABEL,
    TERM_TEXT,
} from '@src/components/details/components/PageHeaderFilters/consts';
import { AmountControl } from '@src/components/ui/AmountControl';
import { TermControl } from '@src/components/ui/TermControl';
import { FILTERS_NAMES } from '@src/constants/filters';

type Props = {
    activeField: string;
    amount: number;
    term: number;
    onChange: (value: number) => void;
    error?: string;
};

export const SheetContent = memo(({ activeField, amount, term, onChange, error }: Props) => {
    const isAmountField = activeField === FILTERS_NAMES.AMOUNT;
    const termPostfix = pluralize(term, 'день', 'дня', 'дней') ?? 'день';

    return (
        <>
            {isAmountField ? (
                <div>
                    <AmountControl
                        name={FILTERS_NAMES.AMOUNT}
                        label={AMOUNT_LABEL}
                        value={amount}
                        onChange={onChange}
                        postfix={AMOUNT_POSTFIX}
                    />
                    <HelperText error={error} text={AMOUNT_TEXT} />
                </div>
            ) : (
                <div>
                    <TermControl
                        name={FILTERS_NAMES.TERM}
                        label={TERM_LABEL}
                        value={term}
                        onChange={onChange}
                        postfix={termPostfix}
                    />
                    <HelperText error={error} text={TERM_TEXT} />
                </div>
            )}
            <div className={styles.tagContainer}>
                {(isAmountField ? AMOUNT_BADGE_LIST : TERM_BADGE_LIST).map(({ label, value }) => (
                    <div
                        key={value}
                        onClick={() => onChange(value)}
                        className={cn(styles.badge, {
                            [styles.inactive]: (isAmountField ? amount : term) !== value,
                            [styles.active]: (isAmountField ? amount : term) === value,
                        })}
                    >
                        {label}
                    </div>
                ))}
            </div>
        </>
    );
});
