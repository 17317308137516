import has from 'lodash/has';
import { useRouter } from 'next/router';
import React, { memo, useMemo } from 'react';

import { Grid, Typography } from '@sravni/react-design-system';
import type { ColSpan } from '@sravni/react-design-system/dist/types/components/Grid/types/Col.types';
import { useDeviceInfoContext, useIsMobile } from '@sravni/react-utils';
import type { Seo } from '@sravni/types/lib/seo';

import type * as Microcredits from '@src/@types/microcredits';
import { MobilePageHeading } from '@src/components/details/components/PageHeaderFilters/components/MobilePageHeading';
import {
    PageHeaderBranding as PageHeaderBrandingBase,
    PageHeaderBrandingContainer,
    PageHeaderLocation,
    PageHeaderRegionsDialog,
    PageHeaderSponsor,
    PageHeaderSubHeading,
    SimplePageHeaderFilters,
    useHeadingTexts,
    useRegionDialogToggle,
} from '@src/components/PageHeader';
import { SimplePageHeaderPhone } from '@src/components/PageHeader/SimplePageHeaderPhone';
import { Experiments } from '@src/constants/abTest';
import { getSpecialHeaderText } from '@src/containers/list/PageHeader/PageHeaderBranding/utils';
import { FullDealEntryPoint } from '@src/containers/list/ProductList/FullDealEntryPoint';
import { sendAmountFilterEvent, sendTermFilterEvent } from '@src/helpers/analyticsEvents';
import { useCreditsExtraFiltersVisibility } from '@src/hooks/useCreditsExtraFiltersVisibility';
import { useTestVariant } from '@src/hooks/useTestVariants';
import { useDeviceBranding } from '@src/reducers/branding';
import { useHasUtmLabel } from '@src/reducers/route';
import { checkIsFullDealBlockVisible } from '@src/utils/checkIsFullDealBlockVisible';
import { isKZLandingPage, isNaKartuPage, isNewSpecialPage, isSpecialPageWithoutQuery } from '@src/utils/routing';
import { PageHeaderFilters } from 'src/components/details/components/PageHeaderFilters';

import styles from './styles.module.scss';

interface IProps {
    filters: Microcredits.IFilters;
    difference?: number;
    onChange: (value: any, name: string) => void;
    seo?: Partial<Seo>;
}

const { Heading } = Typography;

export const PageHeaderBranding: React.FC<IProps> = memo(({ filters, difference, onChange, seo }) => {
    const [conditionsActive] = useCreditsExtraFiltersVisibility();
    const isMobile = useIsMobile();
    const { asPath, query } = useRouter();
    const { creditCalculator } = useDeviceBranding() || {};
    const { isOpenedDialog, closeRegionDialog, toggleRegionDialog } = useRegionDialogToggle();
    const { headingText, subHeadingText } = useHeadingTexts();
    const hasUtmLabel = useHasUtmLabel();
    const span = getSpan(asPath);
    const isSpecial = isSpecialPageWithoutQuery(asPath);
    const isNewSpecial = isNewSpecialPage(asPath);
    const isKZLanding = isKZLandingPage(asPath);

    const [pathWithoutQuery] = asPath.split('?');
    const showNewFilters = isNaKartuPage(pathWithoutQuery);

    const isCRMSpecial = isSpecial && has(query, 'landing_type');
    const specialHeaderText = isCRMSpecial && getSpecialHeaderText(query?.landing_type as string);

    const isPhoneInputShown = (isSpecial && !isCRMSpecial) || pathWithoutQuery === '/zaimy/';

    const { phone: isPhone } = useDeviceInfoContext();
    const { isAVariant } = useTestVariant(Experiments.FULL_DEAL_ENTRY_BLOCK);

    const isFullDealBlockVisible =
        isPhone && isAVariant && checkIsFullDealBlockVisible(pathWithoutQuery) && !hasUtmLabel;

    const FiltersElement = useMemo(() => {
        if (isCRMSpecial || isKZLanding || isNewSpecial) return;

        if (isFullDealBlockVisible) {
            return (
                <>
                    <Heading className={styles.heading} level={1}>
                        {seo?.heading ?? 'Займы'}
                    </Heading>

                    <FullDealEntryPoint />

                    <MobilePageHeading
                        amountChangeAnalyticEvent={() => sendAmountFilterEvent(true)}
                        termChangeAnalyticEvent={() => sendTermFilterEvent(true)}
                        initialAmount={filters.amount ?? 10000}
                        onChange={onChange}
                        initialTerm={filters.term ?? 10}
                    />
                </>
            );
        }

        if (isPhoneInputShown) {
            return <SimplePageHeaderPhone />;
        }

        return showNewFilters ? (
            <PageHeaderFilters initialAmount={filters.amount} initialTerm={filters.term} onChange={onChange} />
        ) : (
            <SimplePageHeaderFilters
                filters={filters}
                difference={difference}
                conditionsActive={conditionsActive}
                onChange={onChange}
                seo={seo}
            />
        );
    }, [
        isCRMSpecial,
        isKZLanding,
        isNewSpecial,
        isPhoneInputShown,
        showNewFilters,
        isFullDealBlockVisible,
        filters,
        onChange,
        difference,
        conditionsActive,
        seo,
    ]);

    if (isMobile) {
        return (
            <>
                {FiltersElement}
                <div className={styles.hidden_heading_section}>
                    <h1>{headingText}</h1>
                    <div>{subHeadingText}</div>
                </div>
            </>
        );
    }

    return (
        <PageHeaderBrandingBase className={styles.page_header_branding} brandingConfig={creditCalculator}>
            <PageHeaderBrandingContainer className={styles.page_header_branding_container}>
                {!isSpecial && !isNewSpecial && (
                    <Grid.Row>
                        <Grid.Col span={isMobile ? span.gridSpanMobile : span.gridSpan}>
                            <PageHeaderLocation
                                className={styles.location}
                                brandingConfig={creditCalculator}
                                position="center"
                                header={headingText}
                                onClick={toggleRegionDialog}
                            />
                            {!hasUtmLabel && <PageHeaderSubHeading>{subHeadingText}</PageHeaderSubHeading>}
                        </Grid.Col>
                        {!isMobile && (
                            <Grid.Col span={span.gridSpanRest} className={styles.sponsor_container}>
                                <PageHeaderSponsor />
                            </Grid.Col>
                        )}
                    </Grid.Row>
                )}
                {isCRMSpecial && Boolean(specialHeaderText) && (
                    <div>
                        <div className={styles.specialTitle}>{specialHeaderText.header}</div>
                        <div className={styles.text}>{specialHeaderText.subHeader}</div>
                    </div>
                )}
                {((isSpecial && !isCRMSpecial) || isNewSpecial) && (
                    <div className={styles.specialTitle}>
                        Займы онлайн от лучших МФО
                        <sup className={styles.star}>*</sup>
                    </div>
                )}
                {FiltersElement}
                {isMobile && <PageHeaderSponsor />}
            </PageHeaderBrandingContainer>
            <PageHeaderRegionsDialog isOpen={isOpenedDialog} onClose={closeRegionDialog} />
        </PageHeaderBrandingBase>
    );
});

interface ISpanObject {
    gridSpan: ColSpan;
    gridSpanRest: ColSpan;
    gridSpanMobile: ColSpan;
}

function getSpan(asPath: string): ISpanObject {
    if (asPath === '/zaimy/pod-0-protsentov/') {
        return {
            gridSpan: 7,
            gridSpanRest: 5,
            gridSpanMobile: 12,
        };
    }

    return {
        gridSpan: 6,
        gridSpanRest: 6,
        gridSpanMobile: 12,
    };
}
